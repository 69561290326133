<template>
    <div class="container">
        <div class="box">
            <img src="@/assets/images/base/mobilePage/logo.svg" alt="" />
            <div class="title">一站式广告智能管理系统</div>
            <div class="button" @click="goWechat">前往微信小程序</div>
            <div class="tip">移动端请前往YinoLink易诺微信小程序进行体验，若想体验全部功能还请使用电脑PC端登录YinoCloud。</div>
        </div>
    </div>
</template>

<script setup>
import {getWechatScheme} from '@/request/api/common'

const goWechat = () => {
    getWechatScheme().then((res) => {
      window.location.href = res.url
    })
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
}
.box {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title {
    width: 244px;
    text-align: justify;
    text-align-last: justify;
    font-size: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #2B82FF;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 85px;
}
.button {
    width: 327px;
    height: 48px;
    background: #2B82FF;
    border-radius: 297px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    margin-bottom: 24px;
}
.tip {
    width: 327px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: normal;
    color: rgba(0,0,0,0.5);
}
</style>